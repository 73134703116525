@import url("https://use.typekit.net/dfq8hts.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  background-color: #000000;
  font-family: sofia-pro, sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

.main-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Ensures full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-player-container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 колонки */
  grid-template-rows: repeat(2, 1fr); /* 2 ряда */
  width: 100vw; /* Полная ширина экрана */
  height: 100vh; /* Полная высота экрана */
  margin: 0; /* Убираем отступы */
  gap: 0; /* Убираем промежутки между ячейками */
}

.video-wrapper {
  position: relative;
  overflow: hidden; /* Убираем переполнение */
}

.video-wrapper video {
  width: 100%; /* Занимает всю ширину */
  height: 100%; /* Занимает всю высоту */
  object-fit: contain; /* Сохраняет пропорции, допускает черные полосы */
}

.video-wrapper.expanded,
.video-wrapper.fullscreen-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 40;
  background: rgba(0, 0, 0); /* Black background overlay */
}

.fullscreen-btn-container,
.reset-view-btn {
  position: absolute;
  top: 20px;
  z-index: 50;
}

.fullscreen-btn-container {
  right: 20px;
}

.reset-view-btn {
  left: 20px;
}

.media-control-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background: rgba(0, 0, 0, 0.7); /* Slightly transparent background */
  color: #fff;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
}

.media-control-bar.hide {
  opacity: 0;
}

.media-control-bar.show {
  opacity: 1;
}

.fullscreen-btn,
.play-pause-btn {
  background-color: transparent;
  color: white;
  border: white solid 1px;
  padding: 0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  cursor: pointer;
  margin: 0 0.1rem;
}

.reset-view-btn {
  background-color: transparent;
  color: white;
  border: white solid 1px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
}

.play-pause-btn:hover,
.fullscreen-btn:hover,
.reset-view-btn:hover {
  background-color: #ffffff;
  color: #000000;
}

.seek-bar {
  width: 300px;
  margin: 0 10px;
}

.time-display {
  font-size: 14px;
  color: white; /* Ensure time display is visible */
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 1rem 0;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.2rem;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #ffffff; /* Inactive track color */
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 0.9rem;
  width: 0.4rem;
  border-radius: 1rem;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -0.4rem;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ffffff;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #ffffff;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #ffffff;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type="range"]::-ms-fill-upper {
  background: #ffffff;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type="range"]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #ffffff;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #ffffff;
}

/* Specific iOS adjustments */
@supports (-webkit-touch-callout: none) {
  .video-player-container {
    min-height: -webkit-fill-available; /* Ensures full height on iOS Safari */
  }
}

.modal {
  position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start; /* см. ниже */
    align-items: center;
    z-index: 99;
    /* Чтобы окно не прилипало к границе
    браузера установим отступы */
    padding:30px 0;
}

hr.new1 {
  border-top: 0.5px solid black;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 0px;
  max-width: 800px;
  margin: 0.4rem;
  text-align: left;
}

.start-viewing-btn {
  margin-top: 20px;
  padding: 0px 20px 10px 4px;
  background-color: transparent;
  color: black;
  border: black solid 0.5px;
  border-radius: 0px;
  cursor: pointer;
  text-transform: lowercase;
}

.start-viewing-btn:hover {
  margin-top: 20px;
  padding: 0px 20px 10px 4px;
  background-color: black;
  color: white;
  border: black solid 0.5px;
  border-radius: 0px;
  cursor: pointer;
  text-transform: lowercase;
}


.video-player-container.blurred {
  filter: blur(5px);
  pointer-events: none;
}

.reset-view-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 0px 20px 10px 4px;
  background: transparent;
  color: white;
  border: white solid 0.5px;
  border-radius: 0px;
  cursor: pointer;
  z-index: 500;
  text-transform: lowercase;
}

@font-face {
  font-family: CSTM Xprmntl;
  font-style: italic;
  font-weight: normal;
  src: 
    url("./fonts/font.eot") format("eot"),
    url("./fonts/font.svg") format("svg")
    url("./fonts/font.ttf") format("truetype"),
    url("./fonts/font.woff") format("woff"),
    url("./fonts/font.woff2") format("woff2");
}

il {
  font-family: "CSTM Xprmntl";
  font-style: italic;
  font-weight: normal;
}
